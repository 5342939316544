a {
    text-decoration: none;
    color: rgb(219, 75, 65);
}

a:visited {
    color: rgb(219, 75, 65);
}

a:hover {
    color: gray;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-center {
    justify-content: center!important;
}

.flex-end {
    justify-content: flex-end;
}

.flex-apart {
    justify-content: space-between;
}

.flex-wrap {
    flex-wrap: wrap;
}

.about-wrapper {
    padding-top: 8%;
    display: flex;
    flex-direction: row;
    justify-content: center!important;
}

.footer {
    clear: both;
    position: relative;
    height: 100px;
    margin-top: -5px;
    font-size: 0.8em;
    color: gray;
    bottom: 0;
    left: 0;
}

.gallery {
    display: flex;
    flex-direction: row;
}

@media (max-width: 800px) {
    .about-wrapper {
      flex-direction: column;
      padding-top: 20%;
    }

    #bio {
        width: auto;
        /* font-size: 1em; */
    }
}

.italics-text {
    font-style: italic;
}

.bold-text {
    font-weight: bold;
}

.topbar-style {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    position: fixed;
}

.topbar-label {
    /* font-size: 1.1em; */
    font-weight: 300;
    letter-spacing: 0.1em;
    border-bottom: 1px solid rgba(255, 0, 0, 0);
}

.topbar-label:hover {
    /* color: #91c58f; */
    cursor: pointer;
    border-bottom: 1px solid #000
}

.icon {
    color: black;
}

.icon:hover {
    color: rgb(219, 75, 65);
    cursor: pointer;
}

.title {
    padding-bottom: 20px;
    font-size: 2.2em;
}

.smaller-font {
    font-size: 0.85em;
}

.pub-title {
    font-weight: bold;
}

.pub-wrapper {
    padding-bottom: 100px;
}

.pub-icon {
    /* max-width: 150px; */
    border-radius: 15px;
    border: 1px dashed rgb(209, 205, 205);
}

.pub-icon:hover {
    /* opacity: 60%; */
    transition: 0.5s ease;
    -webkit-transform: scale(0.95);
	transform: scale(0.95);
}

.pub-text {
    padding-left: 1.4rem;/*25px;*/
    line-height: 1.5;
}

.pub {
    padding: 20px;
    font-weight: 300;
    max-width: 800px;
}

.pub-header {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 0.75em;
}

.award {
    display: inline;
    font-weight: 400;
}

.highlight {
    position: relative;
}

.highlight:before {
    /* Highlight color */
  background-color: rgba(255, 218, 143, 0.614);

  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(-2deg);
}

.highlight-grey {
    position: relative;
}

.highlight-grey:before {
    /* Highlight color */
  background-color: rgba(225, 225, 225, 0.353);

  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(-2deg);
}

.light-text {
    color:rgb(97, 93, 93);
    font-style: italic;
    font-size: 0.9em;
}

.light-text-standard {
    color:rgb(97, 93, 93);
    font-size: 0.8em;
    padding-bottom: 4em;
}

.pad-10 {
    padding: 10px;
}

.pad-10-right {
    padding-right: 10px;
}

.pad-50 {
    padding: 50px;
}

.center {
    text-align: center;
}

.top {
    min-height: calc(100vh - 100px);
}

.bottom {
    /* position: absolute;
    bottom: 0; */
    height: 30px;
}

.dont-center {
    text-align: left;
}

.fun-text {
    max-width: 800px;
    padding: 30px;
}

hr.gray {
    border-top: 1px solid rgb(212, 212, 212);
}

.photo {
    padding: 0;
    border-radius: 125px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 250px;
    height: 250px;
}

.subpage-img {
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3);
    max-width: 100%;
}

.subpage-img-small {
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3);
    max-width: 33.3%;
}

.subpage-img-small-no-shadow {
    max-width: 33.3%;
}

.subpage-smalltext {
    font-size: 0.8em;
    color: rgb(175, 175, 175);
}

.cards-img {
    /* border: 2px solid rgb(255, 255, 255); */
    border-radius: 25px;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3);
    object-fit: cover;
    width: 300px;
    height: 300px;
    object-position: 60% 50%;
}

.cards-text {
    padding: 10px
}

.cards-img:hover {
    /* opacity: 60%; */
    cursor: pointer;
    transition: 0.5s ease;
    -webkit-transform: scale(0.95);
	transform: scale(0.95);
}

.hidden {
    color: white;
    position: absolute;
    right: 30px;
    top: 30px;
}

.hidden:hover {
    color: lightgray;
}

#bio {
    color: rgb(71, 71, 71);
    /* font-size: 1.1em; */
    font-weight: lighter;
    max-width: 500px;
}

#socials {
    text-align: center;
    font-size: 1.5em;
    padding: 20px;
}

#fun-caption {
    padding-bottom: 3%;
    padding-left: 50px;
    padding-right: 50px;
}

.blog-post-title {
    font-size: 1.9em;
    font-weight: lighter;
    color: black;
}

.blog-post-date {
    font-size: 0.8em;
    padding-bottom: 1.2em;
    color: rgb(153, 146, 146);
}

hr{
    height: 1px;
    background-color: #e8e8e8;
    border: none;
}

.wave {
    animation-name: wave-animation;
    animation-duration: 2.5s; 
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
}
  
@keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }
    100% { transform: rotate( 0.0deg) }
}

.bibtex-citation {
    background-color: #f0f0f0; /* Light gray background */
    padding: 10px;             /* Add some padding for better readability */
    border-radius: 5px;        /* Rounded corners */
    font-size: 0.75em;          /* Slightly smaller font size */
    overflow-x: auto; 
    width: 500px;
}